import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { action } from "../../../utils/helperFunctions";
import { SyntheticEvent, useEffect, useState } from "react";
import {
  PRORITHM_URL,
  ambulanceText,
  PRORITHM_TOKEN_1,
  PRORITHM_TOKEN_2,
  PRORITHM_TOKEN_3,
  PRORITHM_TOKEN_4,
  PRORITHM_TOKEN_5,
  ambulance,
} from "../../../utils/constants";
import SpaIcon from "@mui/icons-material/Spa";
import Snackbar from "@mui/material/Snackbar";
import ReactSpeedometer from "react-d3-speedometer";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";

interface PatientData {
  skt: number;
  spo2: number;
  hr: number;
  bp: [number, number];
  patient_id: number;
  respiratory_rate: number;
  battery: number;
}

const AmbulanceLiveData = () => {
  const [message, setMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [data, setData] = useState<PatientData | null>(null);
  const ambulanceIndex = useSelector(
    (state: any) => state.selectedAmbulanceIndex
  );

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (data) {
      setData(null);
    }
    const getToken = () => {
      switch (ambulanceIndex) {
        case 0:
          return PRORITHM_TOKEN_1;
        case 1:
          return PRORITHM_TOKEN_2;
        case 2:
          return PRORITHM_TOKEN_3;
        case 3:
          return PRORITHM_TOKEN_4;
        case 4:
          return PRORITHM_TOKEN_5;
        default:
          return PRORITHM_TOKEN_1;
      }
    };

    const eventSource = new EventSource("" + PRORITHM_URL + getToken());

    eventSource.onmessage = (event) => {
      const newData: PatientData = JSON.parse(event.data);
      console.log("Received data:", newData);
      setData(newData);
    };

    eventSource.onerror = (error: any) => {
      // 0 - connecting, 1 - open, 2 - closed
      if (error?.target?.readyState === EventSource.CLOSED) {
        setOpenSnackbar(true);
        setMessage(ambulance.connectionClosed);
      }
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ambulanceIndex]);

  return (
    <>
      <div id="prorithm-title" className="prorithm-heading">
        <Typography id="pro-text" variant="h2" color="error">
          {ambulanceText.pro}
        </Typography>
        <Typography id="rithm-text" variant="h2" sx={{ color: "#1b5299" }}>
          {ambulanceText.rithm}
        </Typography>
      </div>
      <Grid container>
        <Grid item xs={16} sm={16} md={12} lg={4} xl={4}>
          <div id="heart-rate-card" className="prorithm-vitals-card">
            <div id="heart-rate-heading" className="prorithm-vital-heading">
              <div id="heart-rate-title" className="vital-title">
                <Typography
                  id="heart-rate-text"
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  {ambulanceText.heartRate}
                </Typography>
                <FavoriteIcon
                  id="heart-rate-icon"
                  fontSize="large"
                  color="error"
                />
              </div>
              <div id="from-ecg-box" className="vital-details">
                <Typography id="from-ecg-text" variant="body1">
                  {ambulanceText.fromECG}
                </Typography>
              </div>
            </div>
            <div id="heart-rate-value-box" className="prorithm-vital-value">
              <Typography id="heart-rate-data" variant="h1">
                {data ? data?.hr : "-"}
              </Typography>
              <Typography
                id="bpm-text"
                variant="h4"
                sx={{ alignSelf: "flex-end" }}
              >
                {ambulanceText.bpm}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={16} sm={16} md={12} lg={4} xl={4}>
          <div id="respiratory-rate-card" className="prorithm-vitals-card">
            <div
              id="respiratory-rate-heading"
              className="prorithm-vital-heading"
            >
              <div id="respiratory-rate-title" className="vital-title">
                <Typography
                  id="respiratory-rate-text"
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  {ambulanceText.respiratoryRate}
                </Typography>
                <MonitorHeartIcon
                  id="respiratory-icon"
                  fontSize="large"
                  color="inherit"
                />
              </div>
              <div id="from-ecg-card" className="vital-details">
                <Typography variant="body1">{ambulanceText.fromECG}</Typography>
              </div>
            </div>
            <div id="respiratory-rate-box" className="prorithm-vital-value">
              <Typography id="respiratory-rate-value" variant="h1">
                {data ? data?.respiratory_rate : "-"}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={16} sm={16} md={12} lg={4} xl={4}>
          <div id="spo2-card" className="prorithm-vitals-card">
            <div id="spo2-heading" className="prorithm-vital-heading">
              <div id="spo2-title" className="vital-title">
                <Typography
                  id="spo2-text"
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  {ambulanceText.spo2}
                </Typography>
                <SpaIcon id="spo2-icon" fontSize="large" color="success" />
              </div>
              <div id="processing-from-device-box" className="vital-details">
                <Typography id="processing-from-device-text" variant="body1">
                  {ambulanceText.processingFromDevice}
                </Typography>
              </div>
            </div>
            <div id="spo2-value-box" className="prorithm-vital-value">
              <Typography id="spo2-value" variant="h1">
                {data ? data?.spo2 : "-"}
              </Typography>
              <Typography
                id="percentage-text"
                variant="h3"
                sx={{ alignSelf: "center" }}
              >
                %
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={16} sm={16} md={12} lg={4} xl={4}>
          <div id="skin-temp-card" className="prorithm-vitals-card">
            <div id="skin-temp-heading" className="prorithm-vital-heading">
              <div id="skin-temp-title" className="vital-title">
                <Typography
                  id="skin-temp-text"
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  {ambulanceText.skinTemp}
                </Typography>
                <DeviceThermostatIcon
                  id="skin-temp-icon"
                  fontSize="large"
                  color="success"
                />
              </div>
              <div
                id="processing-from-device-container"
                className="vital-details"
              >
                <Typography id="processing-from-device-title" variant="body1">
                  {ambulanceText.processingFromDevice}
                </Typography>
              </div>
            </div>
            <div className="vitals-gauge">
              <div id="skin-temp-gauge-container" className="skin-temperature">
                <span className="gauge-text">&nbsp;</span>
                {data ? (
                  <ReactSpeedometer
                    value={data?.skt}
                    width={200}
                    height={150}
                    minValue={25}
                    maxValue={50}
                    needleColor="steelblue"
                    needleHeightRatio={0.5}
                    needleTransitionDuration={4000}
                    currentValueText={data?.skt?.toString()}
                    segmentColors={[
                      "#CF2E06",
                      "#EDDF0C",
                      "#0CED1D",
                      "#EDDF0C",
                      "#ED8A0C",
                      "#CF2E06",
                    ]}
                    textColor="#1b5299"
                  />
                ) : (
                  <span className="gauge-text">{ambulance.gaugeText}</span>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={16} sm={16} md={12} lg={8} xl={8}>
          <div id="blood-pressure-card" className="prorithm-vitals-card">
            <div id="blood-pressure-heading" className="prorithm-vital-heading">
              <div id="blood-pressure-title" className="vital-title">
                <Typography
                  id="blood-pressure-text"
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  {ambulanceText.bloodPressure}
                </Typography>
                <FavoriteIcon
                  id="blood-pressure-icon"
                  fontSize="large"
                  color="error"
                />
              </div>
              <div id="processing-from-ecg" className="vital-details">
                <Typography id="processing-from-ecg-text" variant="body1">
                  {ambulanceText.processingFromECG}
                </Typography>
              </div>
            </div>
            <div id="blood-pressure-box" className="vitals-gauge">
              <div id="systolic-bp-box">
                {data ? (
                  <>
                    <span id="systolic-bp-text" className="gauge-text">
                      {ambulanceText.systolic}
                    </span>
                    <ReactSpeedometer
                      value={
                        data?.bp[0] < 40
                          ? 40
                          : data?.bp[0] > 200
                          ? 200
                          : data?.bp[0]
                      }
                      minValue={40}
                      maxValue={200}
                      width={200}
                      height={150}
                      needleColor="steelblue"
                      needleHeightRatio={0.5}
                      segmentColors={[
                        "#CF2E06",
                        "#ED8A0C",
                        "#EDDF0C",
                        "#0CED1D",
                        "#EDDF0C",
                        "#ED8A0C",
                        "#CF2E06",
                      ]}
                      customSegmentStops={[40, 60, 80, 100, 120, 140, 160, 200]}
                      needleTransitionDuration={4000}
                      currentValueText={data?.bp[0]?.toString()}
                      textColor="#1b5299"
                    />
                  </>
                ) : (
                  <span className="gauge-text">{ambulance.gaugeText}</span>
                )}
              </div>
              <div id="diastolic-bp-box">
                {data ? (
                  <>
                    <span id="diastolic-bp-text" className="gauge-text">
                      {ambulanceText.diastolic}
                    </span>
                    <ReactSpeedometer
                      value={
                        data?.bp[1] < 0
                          ? 0
                          : data?.bp[1] > 170
                          ? 170
                          : data?.bp[1]
                      }
                      minValue={0}
                      maxValue={170}
                      width={200}
                      height={150}
                      needleColor="steelblue"
                      needleHeightRatio={0.5}
                      segmentColors={[
                        "#CF2E06",
                        "#ED8A0C",
                        "#EDDF0C",
                        "#0CED1D",
                        "#EDDF0C",
                        "#ED8A0C",
                        "#CF2E06",
                      ]}
                      customSegmentStops={[0, 40, 60, 80, 90, 110, 130, 170]}
                      needleTransitionDuration={4000}
                      currentValueText={data?.bp[1]?.toString()}
                      textColor="#1b5299"
                    />
                  </>
                ) : (
                  <span className="gauge-text">{ambulance.gaugeText}</span>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        id="snackbar-message"
        message={message}
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={3000}
        action={action(handleClose)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
};

export default AmbulanceLiveData;
