import { iPatientDemographicData } from "../interfaces";

export const DMS_API_URL = process.env.REACT_APP_DMS_API_URL;
export const CRITICAL_BEDS_ID = process.env.REACT_APP_CRITICAL_BEDS;
export const IPD_PROJECT_ID = process.env.REACT_APP_IPD_PROJECT_ID;
export const OPD_PROJECT_ID = process.env.REACT_APP_OPD_PROJECT_ID;
export const LABS_PROJECT_ID = process.env.REACT_APP_LABS_PROJECT_ID;
export const CHAT_PROJECT_ID = process.env.REACT_APP_CHAT_PROJECT_ID;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const PRORITHM_URL = process.env.REACT_APP_PRORITHM_URL;
export const APP_TYPE = process.env.REACT_APP_SOCKET_APP_TYPE;
export const PRORITHM_TOKEN_1 = process.env.REACT_APP_PRORITHM_TOKEN_1;
export const PRORITHM_TOKEN_2 = process.env.REACT_APP_PRORITHM_TOKEN_2;
export const PRORITHM_TOKEN_3 = process.env.REACT_APP_PRORITHM_TOKEN_3;
export const PRORITHM_TOKEN_4 = process.env.REACT_APP_PRORITHM_TOKEN_4;
export const PRORITHM_TOKEN_5 = process.env.REACT_APP_PRORITHM_TOKEN_5;
export const INTENSIVIST_DOCTOR_ID =
  process.env.REACT_APP_INTENSIVIST_DOCTOR_ID;
export const JITSI_SECRET_KEY = process.env.REACT_APP_JITSI_SECRET_KEY;
export const JITSI_APP_ID = process.env.REACT_APP_JITSI_APP_ID || "";

const downloadUrl =
  process.env.REACT_APP_DMS_API_URL || "" + process.env.REACT_APP_DMS_ENDPOINT;
const noPatientAvailableText: string =
  "No patients found for your search query/location.";
const naText: string = "N/A";
const actionPanel: string = "Action Panel";
const treatmentAndMedications: string = "Treatment & Medications";
const notInList = "Not in List";
const oneQuantityText = "Qty-1";
const keepEditingText: string = "Keep Editing";
const submit: string = "Submit";
const years: string = "Years";
const enterFullScreen: string = "Enter Full Screen";
const exitFullscreen: string = "Exit Full Screen";
const tests: string = "Tests";
const doctor: string = "Doctor";
const medicationHistory = "Careplan History";
const medications: string = "Medications";
const labSuggestionsText: string = "Treatment Suggestions";
const noMedications: string = "No Medications found";
const admDate: string = "Adm.Date";
const patientDemographicData: iPatientDemographicData = {
  name: "Name",
  uhid: "UHID",
  age: "Age",
  gender: "Gender",
  ward: "Ward Name",
  room: "Room No.",
  bed: "Bed No.",
};

const treatmentAndMedicationsIntent = {
  getDrugsByComposition: "getDrugsByComposition",
  createVisitForCC: "createVisitForCC",
  addToStaging: "addToStaging",
  createNotification: "createNotification",
  getCarePlans: "getCarePlans",
  getDrugsByName: "getDrugsByName",
};

const treatmentAndMedicationsMessages = {
  na: "N/A",
  startDate: "startDate",
  continuous: "Continuous",
  week: "week",
  day: "day",
  month: "month",
  twoWeek: "2 week",
  careplanSuccess: "Careplan created successfully",
  frequencySelectedOnceADay: "Frequency selected as ONCE A DAY",
  frequencySelectedOnceAWeek: "Frequency selected as ONCE A WEEK",
  frequencySelectedTwiceADay: "Frequency selected as TWICE A DAY",
  frequencySelectedTwiceAWeek: "Frequency selected as TWICE A WEEK",
  frequencySelectedThriceADay: "Frequency selected as THRICE A DAY",
  frequencySelectedThreeDaysAWeek: "Frequency selected as THREE DAYS A WEEK",
  frequencySelectedFourTimesADay: "Frequency selected as FOUR TIMES A DAY",
  frequencySelectedFourDaysAWeek: "Frequency selected as FOUR DAYS A WEEK",
  frequencySelectedFiveTimesADay: "Frequency selected as FIVE TIMES A DAY",
  frequencySelectedFiveDaysAWeek: "Frequency selected as FIVE DAYS A WEEK",
  frequencySelectedSixDaysAWeek: "Frequency selected as SIX DAYS A WEEK",
  positiveWholeNumberAccepted: "Only positive whole numbers are accepted.",
  greaterthanZeroandMultipleOfFive:
    "Value must be > 0 and must be in multiple of 0.5",
  valueGreaterThanZero: "Value must be greater than zero.",
  patientIdNotProvided: "Patient Id is not provided",
  locationNotProvided: "Location is not provided",
  noDatesProvided: "No dates provided or dates array is empty",
  unableToCreateCarePlan: "Unable to create Careplan. Please try again.",
  greaterThanZero: "Value must be greater than 0 and must be whole number",
  onHold: "ON HOLD",
  ACCEPTED: "ACCEPTED",
  PROCESSED: "PROCESSED",
  REJECTED: "REJECTED",
  STAGING: "STAGING",
  accepted: "Accepted",
  rejected: "Rejected",
  pending: "Pending",
  onceADay: "Once a day",
  twiceADay: "Twice a day",
  immediately: "Immediately",
  fourTimesADay: "Four times a day",
  thriceADay: "Thrice a day",
  fiveTimesADay: "Five times a day",
  onAlternateDays: "On alternate days",
  onceAWeek: "Once a week",
  twiceAWeek: "Twice a week",
  threeDaysAWeek: "Thrice a week",
  fourDaysAWeek: "Four days a week",
  fiveDaysAWeek: "Five days a week",
  sixDaysAWeek: "Six days a week",
  everyHour: "Every Hour",
  everyTwoHour: "Every 2 hours",
  everyThreeHour: "Every 3 hours",
  everyFourHour: "Every 4 hours",
  everySixHour: "Every 6 hours",
  everyEightHour: "Every 8 hours",
  everyTwelveHour: "Every 12 hours",
  selectAtLeastOneDate: "Please select at least one date",
  selectOneFrequency: "Please select at least one frequency",
  selectTwoFrequencies: "Please select two frequencies",
  selectThreeFrequencies: "Please select three frequencies",
  selectFourFrequencies: "Please select four frequencies",
  selectFiveFrequencies: "Please select five frequencies",
  selectOneDay: "Please select one day",
  selectTwoDays: "Please select two days",
  selectThreeDays: "Please select three days",
  selectFourDays: "Please select four days",
  selectFiveDays: "Please select five days",
  selectSixDays: "Please select six days",
};
const treatmentAndMedicationMecalHistory = {
  serialNO: "Sl. No",
  startTimr: "Start Time",
  actionTime: " Action Time",
  doctorName: "Doctor Name",
  status: "Status",
  reason: "Reason",
};

const patientList = [
  {
    patientId: "patient1",
    patientName: "Patient 1",
  },
  {
    patientId: "patient2",
    patientName: "Patient 2",
  },
  {
    patientId: "patient3",
    patientName: "Patient 3",
  },
  {
    patientId: "patient4",
    patientName: "Patient 4",
  },
  {
    patientId: "patient5",
    patientName: "Patient 5",
  },
];
const ambulanceList = [
  {
    ambulanceId: "ambulance1",
    ambulanceName: "Ambulance 1",
  },
  {
    ambulanceId: "ambulance2",
    ambulanceName: "Ambulance 2",
  },
  {
    ambulanceId: "ambulance3",
    ambulanceName: "Ambulance 3",
  },
  {
    ambulanceId: "ambulance4",
    ambulanceName: "Ambulance 4",
  },
  {
    ambulanceId: "ambulance5",
    ambulanceName: "Ambulance 5",
  },
];

const CONSTANTS = {
  USER_EMAIL: "user-email",
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  USER_ID: "user-id",
  REACT_TOKEN: "react-token",
  USER_TYPE: "user-type",
};

const labReports = {
  slNo: "S No",
  labId: "Lab ID",
  requestedBy: "Requested By",
  status: "Status",
  tat: "TAT",
  view: "View",
  test: "Test",
  doctor: "Doctor",
  dateAndTime: "(Ordered) Date & Time",
  noLabOrdersFound: "No Lab Orders Found",
  reportUnavailableMessage: "Report Unavailable! Please contact administrator",
  completed: "COMPLETED",
};

const diagnosis = {
  slNo: "S No",
  view: "View",
  doctorName: "Doctor Name",
  date: "Date",
  close: "Close",
  provisionalDiagnosis: "Provisional Diagnosis",
  finalDiagnosis: "Final Diagnosis",
  differentialDiagnosis: "Differential Diagnosis",
  investigations: "Investigations/Diagnostics",
  noData: "No Data",
  selectDate: "Select Date",
  showLess: "Show Less",
  showMore: "Show more",
  intensivistCommandCenter: "Intensivist Command Centre",
};

const treatment = {
  selectDate: "Select Date",
  slNo: "S No",
  view: "View",
  doctorName: "Doctor Name",
  date: "Date",
  noData: "No Data",
  close: "Close",
  reasonReferral: "Reason Referral",
  referralRequired: "Referral Required",
  department: "Department",
  treatmentPlan: "Treatment Plan",
  nameOfDoctor: "Name Of the Doctor",
  showLess: "Show less",
  showMore: "Show more",
  treatmentSuggestions: "Treatment Suggestions",
};

const assesmentText = {
  selectDate: "Select Date",
  slNo: "S No",
  view: "Preview",
  download: "Download",
  title: "Title",
  date: "Date",
  noData: "No Data",
  close: "Close",
  reasonReferral: "Reason Referral",
  referralRequired: "Referral Required",
  department: "Department",
  treatmentPlan: "Treatment Plan",
  nameOfDoctor: "Name Of the Doctor",
  showLess: "Show less",
  showMore: "Show more",
  treatmentSuggestions: "Treatment Suggestions",
};

const labReportMedications = {
  slNo: "S No",
  drugName: "Drug Name",
  dose: "Dose",
  doseUnits: "Dose Units",
  duration: "Duration",
  frequency: "Frequency",
  route: "Route",
  startDate: "Start Date",
  endDate: "End Date",
  intervals: "Intervals",
  addInstructions: "Add. Instructions",
  actions: "Actions",
  discontinue: "Discontinue",
  discontinueConfirm: "Are you sure you want to discontinue",
  yes: "Yes",
  no: "No",
};

const medicationsTable = {
  medicineName: "Medicine Name",
  dose: "Dose",
  unit: "Unit",
  route: "Route",
  frequency: "Frequency",
  interval: "Interval",
  duration: "Duration/Units",
  totalQty: "Total Qty",
  startDate: "Start Date",
  endDate: "End Date",
  sos: "SOS/Instructions",
  action: "Action",
  order: "Order",
  searchAlternative: "Search Alternative",
};

export const unitOptions = [
  { name: "Capsule(s)", value: "capsule" },
  { name: "Tablet(s)", value: "tablet" },
  { name: "ml", value: "ml" },
  { name: "mg", value: "mg" },
  { name: "IU", value: "iu" },
  { name: "Drop", value: "drop" },
  { name: "Tablespoon", value: "tablespoon" },
  { name: "Teaspoon", value: "teaspoon" },
  { name: "Unit(s)", value: "unit" },
  { name: "Puff(s)", value: "puff" },
];

export const routeOptions = [
  { name: "Buccal", value: "buccal" },
  { name: "Epidural", value: "epidural" },
  { name: "Inhalation", value: "inhalation" },
  { name: "Infusion", value: "infusion" },
  { name: "Intra-articular", value: "intra-articular" },
  { name: "Intracardiac", value: "intracardiac" },
  { name: "Intradermal", value: "intradermal" },
  { name: "Intraocular", value: "intraocular" },
  { name: "Intraosseous", value: "intraosseous" },
  { name: "Intraperitoneal", value: "intraperitoneal" },
  { name: "Intranasal", value: "intranasal" },
  { name: "Intrathecal", value: "intrathecal" },
  { name: "Intravaginal", value: "intravaginal" },
  { name: "Intravenous", value: "intravenous" },
  { name: "Nasogastric", value: "nasogastric" },
  { name: "Nasal", value: "nasal" },
  { name: "Ophthalmic", value: "ophthalmic" },
  { name: "Oral", value: "oral" },
  { name: "Otic", value: "otic" },
  { name: "Per Rectum", value: "perrectum" },
  { name: "Per-Vaginal", value: "per-vaginal" },
  { name: "Rectal", value: "rectal" },
  { name: "Sub Lingual", value: "sublingual" },
  { name: "Subcutaneous", value: "subcutaneous" },
  { name: "Topical", value: "topical" },
  { name: "Transdermal", value: "transdermal" },
];

export const frequencyOptions = [
  {
    name: treatmentAndMedicationsMessages?.immediately,
    value: treatmentAndMedicationsMessages?.immediately,
  },
  {
    name: treatmentAndMedicationsMessages?.onceADay,
    value: treatmentAndMedicationsMessages?.onceADay,
  },
  {
    name: treatmentAndMedicationsMessages?.twiceADay,
    value: treatmentAndMedicationsMessages?.twiceADay,
  },
  {
    name: treatmentAndMedicationsMessages?.thriceADay,
    value: treatmentAndMedicationsMessages?.thriceADay,
  },
  {
    name: treatmentAndMedicationsMessages?.fourTimesADay,
    value: treatmentAndMedicationsMessages?.fourTimesADay,
  },
  {
    name: treatmentAndMedicationsMessages?.everyFourHour,
    value: treatmentAndMedicationsMessages?.everyFourHour,
  },
  {
    name: treatmentAndMedicationsMessages?.everySixHour,
    value: treatmentAndMedicationsMessages?.everySixHour,
  },
  {
    name: treatmentAndMedicationsMessages?.onAlternateDays,
    value: treatmentAndMedicationsMessages?.onAlternateDays,
  },
  {
    name: treatmentAndMedicationsMessages?.onceAWeek,
    value: treatmentAndMedicationsMessages?.onceAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.twiceAWeek,
    value: treatmentAndMedicationsMessages?.twiceAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.threeDaysAWeek,
    value: treatmentAndMedicationsMessages?.threeDaysAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.fourDaysAWeek,
    value: treatmentAndMedicationsMessages?.fourDaysAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.fiveDaysAWeek,
    value: treatmentAndMedicationsMessages?.fiveDaysAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.sixDaysAWeek,
    value: treatmentAndMedicationsMessages?.sixDaysAWeek,
  },
  {
    name: treatmentAndMedicationsMessages?.continuous,
    value: treatmentAndMedicationsMessages?.continuous,
  },
];

export const durationUnits = [
  { name: "Hour(s)", value: "hour" },
  { name: "Day(s)", value: "day" },
  { name: "Month(s)", value: "month" },
  { name: "Week(s)", value: "week" },
];

export const instructions = [
  { name: "Before meals", value: "beforemeals" },
  { name: "Empty stomach", value: "emptystomach" },
  { name: "After meals", value: "aftermeals" },
  { name: "In the morning", value: "inmorning" },
  { name: "In the evening", value: "inevening" },
  { name: "At bedtime", value: "bedtime" },
  { name: "Immediately", value: "immediately" },
  { name: "As directed", value: "directed" },
];

const treatmentsText = {
  select: "Select",
  noOptionsFound: "No Options Found",
  breakfast: "Breakfast",
  lunch: "Lunch",
  dinner: "Dinner",
  startingFrom: "Starting From",
  selectAnOption: "Select an options",
  add: "Add",
  remove: "Remove",
};

const layout = {
  selectLayout: "Select Layout",
  patientVitals: "Patient Vitals",
  patientData: "Patient Data",
  intensivists: "Intensivists",
  patientDashboard: "Patient Dashboard",
  default: "Default",
};

const ambulanceText = {
  pro: "pro",
  rithm: "RiTHM",
  heartRate: "Heart Rate",
  fromECG: "From ECG",
  bpm: "bpm",
  respiratoryRate: "Respiratory Rate",
  spo2: "SpO2",
  processingFromDevice: "Processing from device",
  skinTemp: "Skin Temp",
  bloodPressure: "Blood Pressure",
  processingFromECG: "Processing from ECG & PPG",
  systolic: "Systolic",
  diastolic: "Diastolic",
  noAmbulanceAvailableText: "No ambulances available",
};

const patientDashboard = {
  mrNumber: "MR Number",
  name: "Name",
  lastVisited: "Last Visited",
  ecg: "ECG",
  bp: "BP",
  spo2: "SpO2",
  respiratoryRate: "Respiratory Rate",
  actions: "Actions",
  loadingPatients: "No patients found for your search query/location",
  view: "View",
  selectLocation: "Select Location",
  selectWard: "Select Ward",
  wardSelection: "Please select a ward",
  patientNotFoundInPod: "Unable to open. Patient not found in POD",
  openingPatientInPod: "Opening patient in POD...",
  refreshPageMessage: "Please refresh the page to receive real-time data.",
  noWardsAvailable: "No wards available",
  critical: "critical",
};

const historyVitals = {
  ecgHeartRate: "ECG Heart Rate",
  systolicBP: "Systolic BP",
  diastolicBP: "Diastolic BP",
  spo2: "SpO2",
  respiratoryRate: "Respiratory Rate",
  pulseRate: "Pulse Rate",
  eightHoursInterval: "Duration: 8Hrs, Interval: 15Mins",
};

const videoCall = {
  or: "OR",
  startVideoCall: "Start Video Call",
  pod1: "POD 1",
  pod2: "POD 2",
  conferenceRoom: "Conference Room",
  selectARoom: "Select a room",
  enterRoomPlaceholder: "Enter room name in title case",
  roomNameHelperText:
    "Use complex room names (Min length: 12) like: CommandCenterUrgentCall",
  selectExistingRoom: "Select existing room",
  selectRoom: "Select a room",
  recordingFeatureUnavailable:
    "Recording facility unavailable. You can still continue with the call.",
};

const medicationAdministration = {
  medicine: "Medicine",
  dosage: "Dosage",
  duration: "Duration(units)",
  route: "Route",
  frequency: "Frequency",
  noData: "No Medicines for the day",
};

const medicationSubmitAlert =
  "Review and confirm the details before submission";

const allocation = {
  assigned: "ASSIGNED",
  critical: "critical",
  nonCritical: "non-critical",
  occupied: "OCCUPIED",
};

const alerts = {
  alertsError:
    "Patient details not found, Please check Patient ID in Mindray device",
  higherThanLimit: "Higher than limit",
  lowerThanLimit: "Lower than limit",
  high: "High",
  low: "Low",
};

const ambulance = {
  connectionClosed: "Connection closed unexpectedly. Server timeout.",
  gaugeText: "...",
};

const historyCharts = {
  ecgHeartRate: "ECG Heart Rate",
  ecgHeartRateCode: "ecgHeartRate",
  systolicBP: "Systolic BP",
  systolicBpValue: "systolicBpValue",
  diastolicBP: "Diastolic BP",
  diastolicBpValue: "diastolicBpValue",
  spo2: "SPO2",
  spo2Value: "spo2",
  respiratoryValue: "Respiratory Value",
  respiratoryValueCode: "respiratoryValue",
  systolicBpLayout: "systolicBP",
  diastolicBpLayout: "diastolicBP",
  currentlyViewing: "Currently viewing:",
  none: "None",
  pulseRateValue: "pulseRateValue",
};

const hospitals = {
  serachPlaceholder: "Name,ID,MR Number",
};

const layoutOptions = [
  {
    value: "none",
    label: "Select Layout",
  },
  {
    value: "patientDashboard",
    label: "Patient Dashboard",
  },
  {
    value: "vitals",
    label: "Patient Vitals",
  },
  {
    value: "patientData",
    label: "Patient Data",
  },
  {
    value: "intensivists",
    label: "Intensivists",
  },
  {
    value: "default",
    label: "Default",
  },
];

const sidebarTexts = {
  logoutConfirm: "Are you sure you want to logout?",
  currentlyLoggedIn: "Currently logged in as",
  yes: "YES",
  no: "NO",
};

const sidebarPatientLayout = {
  vitals: "vitals",
  default: "default",
  intensivists: "intensivists",
  patientData: "patientData",
};

const thopNotificationAlertType = {
  acceptCareplan: "accept-careplan",
  rejectCareplan: "reject-careplan",
  careplan: "careplan",
};

const ChartDataType = {
  ecg: "ecg",
  bloodPressure: "bloodPressure",
  pulse: "pulse",
  resp: "resp",
};

const channelsType = {
  electrocardiogram: "Electrocardiogram",
  pleth: "Pleth",
  bloodPressure: "Blood pressure",
  resp: "Resp",
};

const imageValidationRegex = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
const positiveIntegerRegex = /^[1-9]\d*$/;
const positiveMultipleOfDotFiveRegex =
  /^(?!0(\.0+)?$)(0?\.([05])|[1-9]\d*(\.\d+)?)$/;
const positiveDecimalNumberRegex = /^(?!0(\.0+)?$)(0?\.\d+|[1-9]\d*(\.\d+)?)$/;

export {
  noPatientAvailableText,
  naText,
  patientDemographicData,
  treatmentAndMedicationsIntent,
  treatmentAndMedicationMecalHistory,
  actionPanel,
  treatmentAndMedications,
  notInList,
  oneQuantityText,
  keepEditingText,
  submit,
  CONSTANTS,
  years,
  enterFullScreen,
  exitFullscreen,
  tests,
  doctor,
  medicationHistory,
  medications,
  labSuggestionsText,
  downloadUrl,
  labReports,
  noMedications,
  diagnosis,
  medicationsTable,
  treatmentsText,
  layout,
  ambulanceList,
  labReportMedications,
  ambulanceText,
  admDate,
  patientList,
  patientDashboard,
  treatment,
  imageValidationRegex,
  positiveIntegerRegex,
  positiveMultipleOfDotFiveRegex,
  positiveDecimalNumberRegex,
  assesmentText,
  historyVitals,
  videoCall,
  medicationAdministration,
  allocation,
  medicationSubmitAlert,
  alerts,
  ambulance,
  historyCharts,
  hospitals,
  layoutOptions,
  sidebarTexts,
  thopNotificationAlertType,
  sidebarPatientLayout,
  treatmentAndMedicationsMessages,
  ChartDataType,
  channelsType,
};
