import { createApiUrl } from "./helperFunctions";
import { CHAT_PROJECT_ID } from "./constants";
import { useDispatch } from "react-redux";
import { setDoctors } from "../redux/actions";

const useGetDoctors = () => {
  const dispatch = useDispatch();
  function fetchGetDoctors() {
    const payload = {
      intent: "getDoctorsAPI",
    };

    fetch(createApiUrl(CHAT_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        const doctorsNamesList = data?.entry
          ?.map((item: any, index: number) => {
            const name = item?.resource?.name?.[0];
            if (name?.given && name?.family) {
              return {
                id: index,
                name: `${name?.given?.join(" ")} ${name?.family}`, // Doctor's name
              };
            }
            return null;
          })
          .filter(Boolean);

        if (doctorsNamesList?.length > 0) {
          dispatch(setDoctors(doctorsNamesList));
        }
      })
      .catch((err) =>
        console.error("Error communicating with server. Error code: ", err)
      );
  }

  return { fetchGetDoctors };
};

export default useGetDoctors;
