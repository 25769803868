import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alerts, thopNotificationAlertType } from "../../../utils/constants";
import {
  setCurrentLocation,
  setPatientUHID,
  setSelectedPatientIndex,
  deleteThopNotificationMessage,
} from "../../../redux/actions";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useGetPatientDetails from "../../../utils/useGetPatientDetails";
import { Box, Button, Drawer, List, ListItem } from "@mui/material";
import useSound from "use-sound";
import "./ThopNotificationAlert.css";

const ThopNotifications = () => {
  const dispatch = useDispatch();
  const alertSound = require("../../../assets/AlertSound/alertSound.mp3");
  const [play] = useSound(alertSound, { volume: 0.2 });
  const { fetchPatientDetails } = useGetPatientDetails();

  const notificationMessages = useSelector(
    (state: any) => state?.thopNotificationMessages
  );

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState(notificationMessages?.length);
  const prevNotificationLength = useRef(notificationMessages?.length);

  const handleNotificationClick = () => {
    setDrawerOpen(true);
  };

  const removeNotification = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    e.stopPropagation();
    dispatch(deleteThopNotificationMessage(index));
  };

  const handleAlertItemClick = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    muhid: string,
    location: string
  ) => {
    const patientsList = patientDetails?.masterPatientDetails?.find(
      (subArray: { patientId: string }[]) =>
        subArray?.some(
          (patient: { patientId: string }) => patient?.patientId === muhid
        )
    );

    setDrawerOpen(false);

    if (
      patientDetails?.masterPatientDetails
        ?.flat()
        ?.some((patient: { patientId: string }) => patient?.patientId === muhid)
    ) {
      fetchPatientDetails(muhid);
      dispatch(setPatientUHID(muhid));
      dispatch(setCurrentLocation(location));
      const patientIndex = patientsList?.findIndex(
        (item: { patientId: string }) => item?.patientId === muhid
      );
      dispatch(setSelectedPatientIndex(patientIndex));
    } else {
      alert(alerts?.alertsError);
    }
    removeNotification(e, index);
  };

  const list = () => (
    <Box
      id="drawer-list-container"
      role="presentation"
      onClick={() => setDrawerOpen(false)}
      onKeyDown={() => setDrawerOpen(false)}
      className="notification-list-item-container"
    >
      <List id="drawer-list">
        {notificationMessages?.map((patient, index) => (
          <ListItem
            id="drawer-list-item"
            key={index}
            disablePadding
            className="notification-list-item"
          >
            <Box
              id="drawer-list-item-container"
              onClick={(event) => {
                handleAlertItemClick(
                  event,
                  index,
                  patient?.patientID,
                  patient?.patientLocation
                );
              }}
              className="notificationItem"
            >
              <p id="drawer-list-item-id" className="patientInfo">
                {patient?.patientID} - {patient?.patientLocation}
              </p>
              <p
                id="status-text"
                className={
                  patient?.messageType ===
                  thopNotificationAlertType?.acceptCareplan
                    ? "statusAccepted"
                    : "statusRejected"
                }
              >
                {patient?.message}
              </p>
              {patient?.messageType ===
                thopNotificationAlertType?.rejectCareplan && (
                <p id="reason" className="rejectionReason">
                  {patient?.reason}
                </p>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const patientDetails = useSelector((state: any) => ({
    masterPatientDetails: state?.masterPatientDetails,
  }));

  useEffect(() => {
    if (notificationMessages?.length > prevNotificationLength?.current) {
      play();
    }
    prevNotificationLength.current = notificationMessages?.length;

    setUnreadCount(notificationMessages?.length);
  }, [notificationMessages, play]);

  return (
    <div id="thop-notifications">
      {notificationMessages?.length > 0 ? (
        <Button
          id="thop-alert-button"
          className="alert-button"
          aria-label="show notifications"
          aria-controls={drawerOpen ? "alerts-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={drawerOpen ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleNotificationClick}
        >
          <Badge
            id="thopAlert-badge"
            badgeContent={unreadCount}
            color="secondary"
          >
            <NotificationsIcon id="alert-icon" fontSize="medium" />
          </Badge>
        </Button>
      ) : null}
      <Drawer
        id="alert-drawer"
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className="alertDrawer"
      >
        {list()}
      </Drawer>
    </div>
  );
};

export default ThopNotifications;
