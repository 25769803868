import { MutableRefObject, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { setLayout } from "../../redux/actions";
import { useTheme } from "@mui/material/styles";
import {
  enterFullScreen,
  exitFullscreen,
  layout, sidebarPatientLayout,
  sidebarTexts,
} from "../../utils/constants";
import Alerts from "../Alerts/Alerts";
import {
  Menu,
  Button,
  Dialog,
  Tooltip,
  MenuItem,
  IconButton,
  DialogTitle,
  useMediaQuery,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import VideocamIcon from "@mui/icons-material/Videocam";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import "./Sidebar.css";
import ThopNotificationAlert from "../Alerts/ThopNotificationAlert/ThopNotificationAlert";

const Sidebar = () => {
  const { keycloak }: any = useKeycloak();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const sidebarButtonRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>(0);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const openLayout = Boolean(anchorEl);
  const patientDetails = useSelector((state: any) => ({
    layout: state.layout,
  }));

  const handleLayoutButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLayoutClose = () => {
    setAnchorEl(null);
  };

  const setPatientDashboardLayout = () => {
    dispatch(setLayout("patientDashboard"));
    setAnchorEl(null);
  };

  const setVitalsLayout = () => {
    dispatch(setLayout("vitals"));
    setAnchorEl(null);
  };

  const setPatientDataLayout = () => {
    dispatch(setLayout("patientData"));
    setAnchorEl(null);
  };
  const setIntensivistsLayout = () => {
    dispatch(setLayout("intensivists"));
    setAnchorEl(null);
  };

  const handlePatientDataRoute = (index: number) => {
    if (activeButtonIndex !== null) {
      sidebarButtonRef.current.children[activeButtonIndex].classList.remove(
        "sidebar-button-clicked"
      );
    }
    sidebarButtonRef.current.children[index].classList.add(
      "sidebar-button-clicked"
    );
    setActiveButtonIndex(index);
    navigate("/");
  };

  const handleAmbulanceRoute = (index: number) => {
    if (activeButtonIndex !== null) {
      sidebarButtonRef.current.children[activeButtonIndex].classList.remove(
        "sidebar-button-clicked"
      );
    }
    sidebarButtonRef.current.children[index].classList.add(
      "sidebar-button-clicked"
    );
    setActiveButtonIndex(index);
    navigate("/ambulance");
  };

  const handleHomeKareRoute = (index: number) => {
    if (activeButtonIndex !== null) {
      sidebarButtonRef.current.children[activeButtonIndex].classList.remove(
        "sidebar-button-clicked"
      );
    }
    sidebarButtonRef.current.children[index].classList.add(
      "sidebar-button-clicked"
    );
    setActiveButtonIndex(index);
    navigate("/homekare");
  };

  const handleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.body.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className="sidebar-container normal-mode" id="sidebar-container">
      <div className="logo" id="sidebar-logo" onClick={handleFullScreen}>
        <Tooltip
          id="screen-size-tooltip"
          title={isFullScreen ? exitFullscreen : enterFullScreen}
          arrow
        >
          {isFullScreen ? (
            <FullscreenExitIcon id="exit-fullscreen-icon" />
          ) : (
            <FullscreenIcon id="fullscreen-icon" />
          )}
        </Tooltip>
      </div>
      {patientDetails.layout !== "none" && location.pathname === "/" && (
        <Tooltip id="layout-tooltip" title="Select Layout" arrow>
          <IconButton
            id="layout-btn"
            aria-haspopup="true"
            aria-label="select-layout"
            aria-controls={openLayout ? "basic-menu" : undefined}
            aria-expanded={openLayout ? "true" : undefined}
            onClick={handleLayoutButtonClick}
          >
            <DashboardIcon className="layout-button" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip id="video-tooltip" title="Video Call" arrow>
        <IconButton
          size="large"
          id="video-call-button"
          aria-label="start-video-call"
          aria-haspopup="true"
          onClick={() =>
            window.open("/videoCall", "_blank", "rel=noopener noreferrer")
          }
        >
          <VideocamIcon className="layout-button" />
        </IconButton>
      </Tooltip>
      <Menu
        id="layout-menu"
        anchorEl={anchorEl}
        open={openLayout}
        onClose={handleLayoutClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          id="patient-dashboard-layout"
          onClick={setPatientDashboardLayout}
        >
          {layout.patientDashboard}
        </MenuItem>
        <MenuItem id="patient-vitals-layout" onClick={setVitalsLayout}>
          {layout.patientVitals}
        </MenuItem>
        <MenuItem id="patient-data-layout" onClick={setPatientDataLayout}>
          {layout.patientData}
        </MenuItem>
        <MenuItem id="intensivists-layout" onClick={setIntensivistsLayout}>
          {layout.intensivists}
        </MenuItem>
      </Menu>
      <div
        id="sidebar-buttons-group"
        className="sidebar"
        ref={sidebarButtonRef}
      >
        <div
          id="hospitals-button"
          className="sidebar-button sidebar-button-clicked"
          onClick={() => handlePatientDataRoute(0)}
        >
          <Tooltip id="hospitals-tooltip" title="Hospitals" arrow>
            <LocalHospitalIcon id="hospitals-icon" />
          </Tooltip>
        </div>
        <div
          id="ambulance-button"
          className="sidebar-button"
          onClick={() => handleAmbulanceRoute(1)}
        >
          <Tooltip id="ambulance-tooltip" title="Ambulance" arrow>
            <AirportShuttleIcon id="ambulance-icon" />
          </Tooltip>
        </div>
        <div
          id="homekare-button"
          className="sidebar-button"
          onClick={() => handleHomeKareRoute(2)}
        >
          <Tooltip id="homekare-tooltip" title="HomeKare" arrow>
            <HomeIcon id="homekare-icon" />
          </Tooltip>
        </div>
      </div>
      <div
        id="siderbar-bottom-buttons-group"
        className="sidebar-bottom-button-set"
      >
        {(patientDetails?.layout === sidebarPatientLayout?.vitals ||
          patientDetails.layout === sidebarPatientLayout?.default) && (
          <div id="sidebar-alert-container" className="alert-button-container">
            <Alerts />
          </div>
        )}

        {patientDetails?.layout === sidebarPatientLayout?.intensivists && (
          <div id="sidebar-alert-container" className="alert-button-container">
            <ThopNotificationAlert />
          </div>
        )}
        <div
          id="logout-dialog"
          className="sidebar-button"
          onClick={() => setOpenDialog(true)}
        >
          <Tooltip id="logout-tooltip" title="Logout" arrow>
            <ExitToAppIcon id="logout-icon" />
          </Tooltip>
        </div>
        <Dialog
          id="logout-dialog-confirmation"
          open={openDialog}
          fullScreen={fullScreen}
          onClose={() => setOpenDialog(false)}
        >
          <DialogTitle>{sidebarTexts.logoutConfirm}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {sidebarTexts.currentlyLoggedIn}:&nbsp;
              <b>
                {sessionStorage.getItem("first-name")}
                &nbsp;{sessionStorage.getItem("last-name")}
              </b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDialog(false)}
              id="close-logout-dialog"
            >
              {sidebarTexts.no}
            </Button>
            <Button
              onClick={() => keycloak.logout()}
              autoFocus
              id="confirm-logout"
            >
              {sidebarTexts.yes}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Sidebar;
